import React from 'react';
import { NavLink } from 'react-router-dom';

const HeroSection = () => {
  return (
      <div className="container">
    <div className=" grant-hero-section">
        <div className='hero-content'>
          <h1 style={{ fontSize: '4rem' }}>Ecosystem</h1>
          {/* <p className="subheading">The first non-profit led philanthropic DAO</p> */}
          <p>Rapid rise of AI & Machine Learning technology dawns a new frontier upon us.</p>
          <p>Time to recalibrate our relationship with technology in a more moral, ethical and responsible way is NOW.</p>
          <div className="hero-buttons d-flex">
            <NavLink to="/donate" className="btn btn-first">Donate Now</NavLink>
            <NavLink to="/whitepaper" className="btn btn-first">Whitepaper</NavLink>
          </div>
        </div>
        <div className='ecosystem-image'>
          <img src="/ecosystem.jpg" className='img-fluid' style={{width: '30rem'}} alt='Ecosystem' />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
