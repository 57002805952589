import React, { useState } from 'react'
import AirdropBoxes from './AirdropBoxes'
import AirdropBoxes2 from './AirdropBoxes2'
import { NavLink } from 'react-router-dom'
import {  FaCross} from 'react-icons/fa'

const Airdrop = () => {
    const [showModal, setShowModal] = useState(false);

    const handleModalOpen = () => {
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };
    return (
        <>
            <div className='terms-of-service my-5 pt-5' style={{ textAlign: 'center' }}>
                <h1 className="my-3"> Welcome to the AIBC Airdrop <br />Unlock Your Share of Innovation <br /> Join the Revolution</h1>
                <div className='flex-column' style={{ cursor: 'auto' }} >
                    <p className='hero-para'>The Artificial Intelligence, Blockchain & Cybersecurity (AIBC) Foundation is <br /> thrilled to announce its exclusive Airdrop campaign! This is your chance <br /> to become part of a global movement shaping the future of  artificial <br /> intelligence, blockchain, and cybersecurity technologies.</p>
                </div>
            </div>
            <section className="security-section" id='our-services'>
                <div className='container my-5'>
                    <h2>Connect with us over Telegram</h2>
                    <div className="row m-0 justify-content-center gap-4" style={{ width: 'fit-content !important' }}>
                        {/* <div className=' d-flex jus'> */}
                        <NavLink to="https://t.me/+U2pS1Rk62-05OWE5" className="btn btn-first col-lg-2 col-md-4 col-sm-4 col-6">Telegram Channel</NavLink>
                        <NavLink to="https://t.me/aibcfoundation" className="btn btn-first col-lg-2 col-md-4 col-sm-4 col-6">Telegram Group</NavLink>
                    </div>
                </div>
            </section>
            <section className="security-section" id='our-services'>
                <div className='container my-5'>
                    <h2>How to Claim Your Free AIBC Tokens</h2>
                    <p>Getting started is as easy as 1 2 3</p>
                    <div className="features mb-4">
                        <div className="feature-card d-flex flex-column align-items-center">
                            <h2 className=' d-flex justify-content-center align-items-center' style={{ background: 'white', width: '50px', height: '50px', color: '#8c61be', borderRadius: '50%', fontSize: '2rem' }}>1</h2>
                            <p className='mb-0'>Download our apps <br /> <NavLink style={{ color: '#fff' }} to='https://apps.apple.com/us/app/shuffle-by-krizaar/id6736882167'>Shuffle</NavLink> and <NavLink style={{ color: '#fff' }} to='https://play.google.com/store/apps/details?id=com.app.krizaar'>Krizaar</NavLink></p>
                        </div>
                        <div className="feature-card d-flex flex-column align-items-center">
                            <h2 className=' d-flex justify-content-center align-items-center' style={{ background: 'white', width: '50px', height: '50px', color: '#8c61be', borderRadius: '50%', fontSize: '2rem' }}>2</h2>
                            <p className='mb-0'>Sign up on Affluence <br /> or <NavLink className='text-white' to='https://krizaar.com/affilate_register'>click here</NavLink></p>
                        </div>
                        <div className="feature-card d-flex flex-column align-items-center">
                            <h2 className=' d-flex justify-content-center align-items-center' style={{ background: 'white', width: '50px', height: '50px', color: '#8c61be', borderRadius: '50%', fontSize: '2rem' }}>3</h2>
                            <p className='mb-0'>Start performing <br /> Affiliate or Airdrop tasks</p>
                        </div>
                    </div>
                    <p>Earn USDC or apply your earnings towards AIBC tokens for your membership within the DAO</p>
                    <p>
                        If you need additional guidance on how to get involved in this Airdrop, you can watch
                        <span
                            style={{ color: '#8c61be', cursor: 'pointer', marginLeft: '8px', textDecoration: 'underline' }}
                            onClick={handleModalOpen} data-toggle="modal" data-target="#exampleModal">
                            this tutorial video
                        </span>.
                    </p>
                </div>

            </section>
            {showModal && (
                <div className="modal show d-block" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content bg-black pt-5">
                            <div className="modal-body d-flex justify-content-center">
                                <video
                                    autoPlay
                                    controls
                                    style={{
                                        display: 'block',
                                        maxWidth: '18rem',
                                        height: 'auto',
                                    }}
                                >
                                    <source src="/tutorial-video.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-first" onClick={handleModalClose}>
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <section className="security-section" id='our-services'>
                <div className='container my-5'>
                    <h2>Rules of Engagement</h2>
                    <p style={{ fontSize: '1.5rem' }}>This Airdrop campaign will run from December 26th, 2024 to March 31st, 2025</p>
                    <p style={{ fontSize: '1.5rem' }}>Airdrop AIBC tokens will be distributed on April 15th, 2025</p>
                    <p style={{ fontSize: '1.5rem' }}>Participate in performing tasks and earn points</p>
                    <p style={{ fontSize: '1.5rem' }}>Convert your points to AIBC tokens at the end of the campaign or redeem them for USDC</p>
                    <p style={{ fontSize: '1.5rem' }}>Perform tasks to help us improve our platforms</p>
                    <p style={{ fontSize: '1.5rem' }}>New tasks will be uploaded weekly on Monday</p>
                    <p style={{ fontSize: '1.5rem' }}>Once the task is completed, upload the supporting evidence for moderator to approve</p>
                    <p style={{ fontSize: '1.5rem' }}>Earned points will accumulate within your wallet</p>
                    <p style={{ fontSize: '1.5rem' }}>At completion, enter your withdrawal instructions within your wallet</p>
                </div>
            </section>
            <AirdropBoxes2 />
            {/* <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                    <div className=' d-flex gap-4'>
                        <div className='d-flex flex-column justify-content-center align-items-start'>
                            <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Why Participate in the AIBC Airdrop?</h5></div>
                            <div className=' d-flex gap-3 mt-1'>
                                <div style={{ textAlign: 'justify' }}>By participating in the AIBC Token Airdrop, you gain:
                                    <ul>
                                        <li><b>Free AIBC Tokens: </b> A hyper-deflationary ERC20 token built on the Polygon blockchain.</li>
                                        <li><b>Governance RIghts: </b> A voice in shaping the future of the AIBC decentralized ecosystem.</li>
                                        <li><b>Exclusive Member Benefits: </b> Enjoy perks like discounts, staking rewards, NFTs, and more.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                    <div className=' d-flex gap-4'>
                        <div className='d-flex flex-column justify-content-center align-items-start'>
                            <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>What is the AIBC Token?</h5></div>
                            <div className=' d-flex gap-3 mt-1'>
                                <div style={{ textAlign: 'justify' }}>The AIBC Token is the backbone of our decentralized autonomous organization (DAO). It powers our mission to drive innovation in AI, blockchain, and cybersecurity while enabling transparency, trust, and decentralization.<br />
                                    <b>Key Features of the AIBC Token:</b>
                                    <ul>
                                        <li><b>Built on Polygon: </b> Faster and cost-effective transactions.</li>
                                        <li><b>Hyper-Deflationary: </b> Limited supply ensures value growth over time.</li>
                                        <li><b>Utility & Governance: </b> Participate in decision-making, staking, and cross-chain swaps.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            {/* <div className='terms-of-service my-5 pt-5 text-center'>
                <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                    <div className=' d-flex gap-4'>
                        <div className='d-flex flex-column justify-content-center align-items-start'>
                            <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>How to Claim Your Free AIBC Tokens</h5></div>
                            <div className=' d-flex gap-3 mt-1'>
                                <div style={{ textAlign: 'justify' }}>Getting started is as easy as 1 2 3<br />
                                    <ol>
                                        <li>Download our apps Shuffle and Krizaar</li>
                                        <li>Sign up on Affluence or click here</li>
                                        <li>Start performing Affiliate or Airdrop tasks</li>
                                    </ol>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <AirdropBoxes />
            <div className='terms-of-service my-5'>
                <div className='checkout__step-header d-flex justify-content-center' style={{ cursor: 'auto' }} >
                    <div className=' d-flex gap-4'>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <div className="checkout__step-name d-flex justify-content-center align-items-center"><h1 className='m-0'>Be Part of the Change</h1></div>
                            <div className=' d-flex gap-3 mt-1'>
                                <div style={{ textAlign: 'center' }}>
                                    <p className='hero-para'>With AIBC, You Join A Global Movement to Revolutionize the Way Technology Serves Humanity</p>
                                    <p className='hero-para'>Decentralized Social Media, E-Commerce, Logistics and Financial Payment Systems</p>
                                    <p className='hero-para'>Together let’s build a future powered by transparency, trust and cutting-edge technology </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='checkout__step-header d-flex justify-content-center mb-5 pb-5' style={{ cursor: 'auto' }} >
                    <div className=' d-flex gap-4'>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <div className="checkout__step-name d-flex justify-content-center align-items-center"><h1 className='m-0'>Act Now!<br /> Limited Tokens Available</h1></div>
                            <div className=' d-flex gap-3 mt-1'>
                                <div style={{ textAlign: 'center', fontSize: '1.5rem' }}>The AIBC Airdrop is available for a limited time, and tokens are distributed on a first-come, first-served basis. Don’t miss your chance to claim your stake in the future of decentralized innovation.<br />
                                    <h1>
                                        Claim Your AIBC Tokens Today!<br />
                                    </h1>
                                    Join the campaign now and become a driving force for change.<br /> Together, let’s build a future powered by transparency, trust, and cutting-edge technology.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Airdrop
