import React from 'react'
import CommonOtherLinkComponent from '../../components/KrizaarPay/CommonOtherLink'

const SellersClub = () => {
  const contentArray = [
    {
      id: 1,
      subtitle: 'Global Reach, Local Impact',
      content: 'Break boundaries and sell your products across the globe, connecting with buyers in North America, Europe, Asia and beyond. Expand your business to new horizons and make your mark in the world’s largest markets.',
      image: '/images/sellersclub/pic2.jpg'
    },
    {
      id: 2,
      subtitle: 'Double Your Selling Power',
      content: 'Maximize your exposure by selling on two powerful platforms, Krizaar and Shuffle. With the Seller’s Club, your products automatically get listed on both our cutting-edge e-commerce platform and our dynamic social media hub, Shuffle.',
      image: '/images/sellersclub/pic3.jpg'
    },
    {
      id: 3,
      subtitle: 'Effortless Fulfillment',
      content: 'Choose your path—handle fulfillment your way or let us take care of it with our seamless in-house logistics services, designed for hassle-free global delivery.',
      image: '/images/sellersclub/pic4.jpg'
    },
    {
      id: 4,
      subtitle: 'Blockchain-Driven Payments',
      content: 'Experience the future of finance with blockchain and USDC. Get paid instantly upon making a sale, and convert your earnings into USDC or fiat currency at your convenience (additional terms apply).',
      image: '/images/sellersclub/pic5.jpg'
    },
    {
      id: 5,
      title: 'Seller Onboarding: Quick, Secure, and Straightforward',
      subtitle: 'Join a Trusted Marketplace',
      content: `At the Seller’s Club, we value security and trust. Our simple onboarding process ensures that only verified sellers join our marketplace, creating a safe environment for all.\n
        • KYC Verification: Quickly verify your identity to start selling. This essential step ensures the integrity of our platform and builds trust with buyers.
        
        • KYB Verification: Representing a business? Our KYB process confirms your company’s legitimacy, protecting both you and your customers.
      `,
      image: '/images/sellersclub/pic6.jpg'
    },
    {
      id: 6,
      title: 'Selling to Global Markets',
      subtitle: 'Efficient and Reliable Fulfillment',
      content: `• Selling to North American Consumers:
          Choose between using our reliable in-house logistics services or managing your own. Either way, connect with a vast market eager for your products.
        
          • Selling to European and Asian Consumers:
          For seamless and timely delivery across Asia, leverage our in-house logistics services. We ensure your products reach buyers with ease, giving them peace of mind and building your brand’s reputation.
      `,
      image: '/images/sellersclub/pic7.jpg'
    }

  ]

  const heroSectionItems = {
    title: 'Welcome to the Sellers Club',
    subtitle: 'Your Global Marketplace for Web2 and Web3 Commerce',
    content2: "Join the Seller’s Club, where cutting-edge technology meets seamless global commerce. Whether you're an established brand or a new seller, The Seller’s Club empowers you to reach consumers across global markets. Tap into new markets and connect with a world of buyers eager for unique products.",
    image: '/images/sellersclub/pic1.jpg'
  }

  const middleBannerContent = {
    title: "Seamless Payouts",
    subtitle: "Get Paid with Ease and Confidence",
    description: `
      We believe in prompt, transparent payments. Here’s how our payout process works:
    `,
    steps: [
      {
        title: `1. Instant USDC:
        `,
        description: `Once a product is sold, you will receive your payment within your wallet. However, you will not be able to redeem these funds for a period of 15 days.`,
      },
      {
        title: "2. Convert to USD or USDC:",
        description: `After the product is delivered and the 14-day refund window closes, your KRZA will be converted to USDC.`,
      },
      {
        title: "3. Flexible Payout Options:",
        description: `Transfer your USDC to PayPal, or convert it directly into fiat currency through your connected bank account using our secure mobile applications.`,
      }
    ],
  };

  return (
    <>
      <CommonOtherLinkComponent title="Why Choose The Seller's Club?" contentArray={contentArray} heroSectionItems={heroSectionItems} middleBannerContent={middleBannerContent} loginPage='https://krizaar.com/vendorlogin' />
    </>
  )
}

export default SellersClub
