import React from 'react';
import { NavLink } from 'react-router-dom';

const MembershipResources = () => {
    return (
        <>
            <div className="membership-resources  text-dark py-0">
                <div className="content">
                    {/* <h1>Membership</h1> */}
                    <h2>Join the AIBC Movement</h2>
                    {/* <p className='text-start'>
                        When you donate to AIBC Foundation, you become an integral part of a global network committed to transformative change. As a member, you’ll enjoy exclusive benefits, including:
                        <div className=' text-start'>
                        <li>Access to AIBC-exclusive events promoting thought leadership.</li>
                        <li>Discounts on products from Krizaar.com.</li>
                        <li>NFT rewards, staking opportunities, and free token swap services.</li>
                        </div>
                        Join us and be a catalyst for ethical innovation!

                    </p> */}
                </div>
            </div>
        </>
    );
};

export default MembershipResources;
