import React from 'react'
import Header from './Header'
import Footer from './Footer'
import TokenPrice from './components/TokenPrice'
import Home from './pages/Home'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { StatisticsProvider } from './contexts/statisticsContext'
import Grants from './pages/Grants'
import Proposals from './pages/Proposals'
import SelectProposals from './pages/SubmitProposal/SelectProposals'
import CreateGovernanceProposal from './pages/SubmitProposal/CreateGovernanceProposal'
import CreateGrantmakingProposal from './pages/SubmitProposal/CreateGrantmakingProposal'
import CreateMembershipProposal from './pages/SubmitProposal/CreateMembershipProposal'
import CreateKrizzarProposal from './pages/SubmitProposal/CreateKrizzarProposal'
import VoteGovernanceProposal from './pages/SubmitProposal/VoteGovernanceProposal'
import VoteGrantmakingProposal from './pages/SubmitProposal/VoteGrantmakingProposal'
import VoteMembershipProposal from './pages/SubmitProposal/VoteMembershipProposal'
import VoteKrizzarProposal from './pages/SubmitProposal/VoteKrizzarProposal'
import Donate from './pages/Donate'
import DonateCheckout from './pages/DonateCheckout'
import DonationTerms from './pages/DonationTerms'
import Swap from './pages/SwapStake/Swap'
import Stake from './pages/SwapStake/Stake'
import Affluence from './pages/KrizaarPay/KrizaarAffluence'
import SellersClub from './pages/KrizaarPay/SellersClub'
import Ecosystem from './Ecosystem'
import Tokenomics from './Tokenomics'
import Airdrop from './Airdrop'
import Staking from './pages/SwapStake/Staking'
import WhitePaper from './WhitePaper'

const App = () => {
  return (
    <StatisticsProvider>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ecosystem" element={<Grants />} />
          <Route path="/proposals" element={<SelectProposals />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/donationterms" element={<DonationTerms />} />
          <Route path="/donate/checkout" element={<DonateCheckout />} />
          <Route path="/proposals/select" element={<SelectProposals />} />
          <Route path="/proposals/create/governance" element={<CreateGovernanceProposal />} />
          <Route path="/proposals/create/grantmaking" element={<CreateGrantmakingProposal />} />
          <Route path="/proposals/create/membership" element={<CreateMembershipProposal />} />
          <Route path="/proposals/create/community" element={<CreateKrizzarProposal />} />
          <Route path="/proposals/vote/governance/:id" element={<VoteGovernanceProposal />} />
          <Route path="/proposals/vote/grantmaking/:id" element={<VoteGrantmakingProposal />} />
          <Route path="/proposals/vote/membership/:id" element={<VoteMembershipProposal />} />
          <Route path="/proposals/vote/krizzar/:id" element={<VoteKrizzarProposal />} />
          <Route path="/swap" element={<Swap />} />
          <Route path="/stake" element={<Stake />} />
          <Route path="/affluence" element={<Affluence />} />
          <Route path="/sellersclub" element={<SellersClub />} />
          <Route path="/ecosystem" element={<Ecosystem />} />
          <Route path="/tokenomics" element={<Tokenomics />} />
          <Route path="/airdrop" element={<Airdrop />} />
          <Route path="/staking" element={<Staking />} />
          <Route path="/whitepaper" element={<WhitePaper />} />
        </Routes>
        <Footer />
        <TokenPrice />
      </BrowserRouter>
    </StatisticsProvider>
  )
}

export default App
