import React from 'react';

const AirdropBoxes2 = () => {
    return (
        <section className="security-section" id='our-services'>
            <div className='container my-5'>
                <h2>Why Participate in the AIBC Airdrop?</h2>
                <p>By participating in the AIBC Token Airdrop, you gain </p>
                <div className="features">
                    <div className="feature-card">
                        {/* <img src="/images/svg1.svg" alt="3D Secure checkouts" /> */}
                        <h3>Earn AIBC Tokens or USDC</h3>
                        <p className='m-0'>A hyper-deflationary ERC20 token built on the Polygon blockchain.
                        </p>
                    </div>
                    <div className="feature-card">
                        {/* <img src="/images/svg2.svg" alt="PCI-compliant servers" /> */}
                        <h3>Governance Rights</h3>
                        <p>A voice in shaping the future of the AIBC decentralized ecosystem.</p>
                    </div>
                    <div className="feature-card">
                        {/* <img src="/images/svg3.svg" alt="Payment data encryption" /> */}
                        <h3>Exclusive Member Benefits</h3>
                        <p>Enjoy perks like discounts, staking rewards, NFTs, and more.</p>
                    </div>
                </div>
            </div>
            <div className='container my-5'>
                <h2>What is the AIBC Token?</h2>
                <p>A hyper-deflationary governance token built on the ERC 20 Polygon network that drives utility and governance within the AIBC ecosystem.<br/>
                <b>Key Features of the AIBC Token: </b> </p>
                <div className="features">
                    <div className="feature-card">
                        {/* <img src="/images/svg1.svg" alt="3D Secure checkouts" /> */}
                        <h3>Built on Polygon</h3>
                        <p className='m-0'> Faster and cost-effective transactions.
                        </p>
                    </div>
                    <div className="feature-card">
                        {/* <img src="/images/svg2.svg" alt="PCI-compliant servers" /> */}
                        <h3>Hyper-Deflationary</h3>
                        <p>Limited supply ensures value growth over time.</p>
                    </div>
                    <div className="feature-card">
                        {/* <img src="/images/svg3.svg" alt="Payment data encryption" /> */}
                        <h3>Utility & Governance</h3>
                        <p>Participate in decision-making, staking, and cross-chain swaps.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AirdropBoxes2;
