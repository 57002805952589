import React from "react";
import { NavLink } from "react-router-dom";
const HowItWorks = () => {
    return (
        <section className="how-it-works-section">
            <div className="how-it-works-header">
                <span className="badge">HOW IT WORKS</span>
                <h2 className="how-it-works-title">
                    Start Making A Profit With Crypto Staking Right Now!
                </h2>
            </div>
            <div className="how-it-works-box mb-5">
                <div className="steps-container-vertical mb-5">
                    {/* Step 1 */}
                    <div className="step">
                        <div className="circle"></div>
                        <div className="step-content">
                            <p>
                            Staking only accepts USDC
                            </p>
                        </div>
                        <div className="vertical-line"></div>

                    </div>
                    {/* Step 2 */}
                    <div className="step">
                        <div className="circle"></div>
                        <div className="step-content">
                            <p>Liquidity contributed to the smart contract is applied to purchase of inventory</p>
                        </div>
                        <div className="vertical-line"></div>

                    </div>
                    {/* Step 3 */}
                    <div className="step">
                        <div className="circle"></div>
                        <div className="step-content">
                            <p>
                            Minimum threshold of $10,000 will activate the inventory acquisition process.
                            </p>
                        </div>
                        <div className="vertical-line"></div>

                    </div>
                    <div className="step">
                        <div className="circle"></div>
                        <div className="step-content">
                            <p>
                            Once Order ID is generated the contract initiates
                            </p>
                        </div>
                        <div className="vertical-line"></div>

                    </div>
                    <div className="step">
                        <div className="circle"></div>
                        <div className="step-content">
                            <p>
                            Funds can no longer be unstaked thereafter
                            </p>
                        </div>
                        <div className="vertical-line"></div>

                    </div>
                    <div className="step">
                        <div className="circle"></div>
                        <div className="step-content">
                            <p>
                            Once Invoice ID is generated from supplier, inventory token is minted
                            </p>
                        </div>
                        <div className="vertical-line"></div>

                    </div>
                    <div className="step">
                        <div className="circle"></div>
                        <div className="step-content">
                            <p>
                            Liquidity Providers receive wrapped USDC and Inventory token as collateral
                            </p>
                        </div>
                        <div className="vertical-line"></div>

                    </div>
                    <div className="step">
                        <div className="circle"></div>
                        <div className="step-content">
                            <p>
                            USDC is released to the supplier in exchange for the inventory
                            </p>
                        </div>
                        <div className="vertical-line"></div>

                    </div>
                    <div className="step">
                        <div className="circle"></div>
                        <div className="step-content">
                            <p>
                            At maturity, original USDC plus rewards are returned to the liquidity provider
                            </p>
                        </div>
                    </div>
                    <div className="step">
                        <div className="circle"></div>
                        <div className="step-content">
                            <p>
                            Wrapped USDC & Inventory token are burned and contract closes
                            </p>
                        </div>
                    </div>
                </div>
                <NavLink to='/stake' className="start-staking-button text-decoration-none mt-5">Start staking</NavLink>
            </div>
        </section>
    );
};

export default HowItWorks;
