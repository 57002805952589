import React from 'react'
import HeroSection from './HeroSection'
import '../../assets/css/commonotherlink.css'
import Content from './Content'
import Header from './Header'

const CommonOtherLinkComponent = ({ contentArray, heroSectionItems, middleBannerContent, title, loginPage }) => {
  return (
    <>
      <div className='krizaar-pay'>
        {/* <Header/> */}
        <HeroSection heroSectionItems={heroSectionItems} loginPage={loginPage} />
        <Content contentArray={contentArray} title={title} middleBannerContent={middleBannerContent} />
      </div>
    </>
  )
}

export default CommonOtherLinkComponent
