import React from "react";
import { NavLink } from "react-router-dom";

const StakingPageHeroSection = () => {
  return (
    <div className="staking-page-hero-section">
      <h1>
        Earn Rewards With The <span className="staking-page-gradient-text">Real World Staking Platform </span> On{" "}
        <span className="staking-page-gradient-text">AIBC</span>
      </h1>
      <p className="mb-1">
      A global peer to peer microfinance platform.
      </p>
      <p className="mb-1">Global commerce needs liquidity to mobilize trade.</p>
      <p className="mb-1">Participate in our staking platform to take advantage of global trade and finance opportunities in order to facilitate inventory financing.</p>
      <p>Staking Platform is managed and operated by Kriza Dropship Pvt LTD</p>
      <NavLink to='/stake' className="staking-page-cta-button text-decoration-none">Launch Now</NavLink>
      <div className="staking-page-image">
        <img src="/images/staking-hero.png" alt="Crypto Staking Platforms" />
      </div>
    </div>
  );
};

export default StakingPageHeroSection;
