import React from 'react';
import { NavLink } from 'react-router-dom';

const ImpactSection = () => {
    return (
        <>
            <div className='terms-of-service mt-0'>
                <h1>We are now Live!!! <br />Download our app Shuffle Hub!!!</h1>
                <div className="get-your-app d-flex justify-content-center gap-3 mt-3">
                    <NavLink to='https://apps.apple.com/us/app/shuffle-by-krizaar/id6736882167'>
                        <img src="/app-store.png" className="img-fluid" style={{ width: '10rem' }} alt="App Store" />
                    </NavLink>
                    <NavLink to='https://play.google.com/store/apps/details?id=vr.shuffle.hub'>
                        <img src="/play-store-button.webp" className="img-fluid" style={{ width: '10rem' }} alt="App Store" />
                    </NavLink>
                </div>
            </div>
            <div className="impact-container">
                {/* <img src={img} className='img-fluid impact-background' alt='background-img' /> */}
                {/* <img src={leaf} className='img-fluid leaf1' alt='background-img' />
                <img src={leaf} className='img-fluid leaf2' alt='background-img' /> */}
                <div className='container'>
                    <div className="big-green-impact mb-5 mt-5 pb-5 d-flex flex-column align-items-center">
                        <h2><span>Mission:<br /> Driving Ethical Innovation</span></h2>
                        <p>Our mission is to build, promote, oversee & administer the global development of moral, ethical & responsible application of artificial intelligence, blockchain and cybersecurity technologies for the advancement & betterment of humanity.</p>
                        <p>The Artificial Intelligence, Blockchain & Cybersecurity (AIBC) Foundation Inc. is a registered 501(c)(3) non-profit organization in the United States.</p>
                        <div className="hero-buttons row d-flex justify-content-center" style={{ width: 'fit-content' }}>
                            {/* <div className=' d-flex gap-4'> */}
                            <NavLink to="/ecosystem" className="btn btn-first col-lg-3 col-md-5 col-sm-5 col-5">Ecosystem</NavLink>
                            <NavLink to="/whitepaper" className="btn btn-first col-lg-3 col-md-5 col-sm-5 col-5">Whitepaper</NavLink>
                            {/* <a href="/AIBC_Determination_Letter.pdf" className="btn btn-first col-lg-3 col-md-5 col-sm-5 col-5" download="AIBC_Determination_Letter.pdf">Determination Letter</a> */}
                            {/* </div> */}
                            <NavLink to="/donate" className="btn btn-first col-lg-3 col-md-5 col-sm-5 col-5">Donate Now</NavLink>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default ImpactSection;
