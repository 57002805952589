import React from 'react'

const Tokenomics = () => {
    return (
        <>
            <div className='terms-of-service my-5 pt-5'>
                <h1 className="my-3">Tokenomics: <br />The AIBC DAO Governance Token</h1>
                <div className='checkout__step-header d-flex justify-content-center' style={{ cursor: 'auto' }} >
                    <p className='hero-para'>AIBC token is a hyper-deflationary token built on the ERC20 polygon network.</p>
                </div>
                <section className="security-section" id='our-services'>
                    <div className='container mt-5'>
                        <h2>AIBC by the numbers</h2>
                        <div className="features">
                            <div className="feature-card bg-black">
                                <h3 className='bg-black'>Fixed Supply</h3>
                                <h3 className='mb-0 bg-black'>150M
                                </h3>
                            </div>
                            <div className="feature-card bg-black">
                                <h3 className='bg-black'>Liquidity Pool</h3>
                                <h3 className='mb-0 bg-black'>25M</h3>
                            </div>
                            <div className="feature-card bg-black">
                                <h3 className='bg-black'>Insiders</h3>
                                <h3 className='mb-0 bg-black'>2.5M</h3>
                            </div>
                            <div className="feature-card bg-black">
                                <h3 className='bg-black'>Airdrop</h3>
                                <h3 className='mb-0 bg-black'>2.5M
                                </h3>
                            </div>
                            <div className="feature-card bg-black">
                                <h3 className='bg-black'>Future Release</h3>
                                <h3 className='mb-0 bg-black'>70M
                                </h3>
                            </div>
                            <div className="feature-card bg-black">
                                <h3 className='bg-black'>Developer</h3>
                                <h3 className='mb-0 bg-black'>50M
                                </h3>
                            </div>
                        </div>
                        <p className='mt-4'>The AIBC DAO funds distribution mechanism</p>
                    </div>
                </section>
                <section className="security-section" id='our-services'>
                    <div className='container mt-5'>
                        <h2>AIBC Donations</h2>
                        <div className="features">
                            <div className="feature-card bg-black">
                                <h3 className='bg-black'>Proposals</h3>
                                <h3 className='mb-0 bg-black'>75%
                                </h3>
                            </div>
                            <div className="feature-card bg-black">
                                <h3 className='bg-black'>Operating Expenses</h3>
                                <h3 className='mb-0 bg-black'>20%</h3>
                            </div>
                            <div className="feature-card bg-black">
                                <h3 className='bg-black'>Liquidity Pool</h3>
                                <h3 className='mb-0 bg-black'>5%</h3>
                            </div>

                        </div>
                        <p className='mt-4'>Each time token sells, 5% royalty will be charged by AIBC per transaction</p>
                    </div>
                </section>
                <section className="security-section" id='our-services'>
                    <div className='container my-5'>
                        <h2>Royalty Distribution</h2>
                        <div className="features">
                            <div className="feature-card bg-black">
                                <h3 className='bg-black'>Liquidity Pool</h3>
                                <h3 className='mb-0 bg-black'>2%</h3>
                            </div>
                            <div className="feature-card bg-black">
                                <h3 className='bg-black'>AIBC Token Burn</h3>
                                <h3 className='mb-0 bg-black'>2%
                                </h3>
                            </div>
                            <div className="feature-card bg-black">
                                <h3 className='bg-black'>AIBC Income</h3>
                                <h3 className='mb-0 bg-black'>1%</h3>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Tokenomics
