import React from 'react';

const ContentMiddleBanner = ({ middleBannerContent }) => {
    return (
        <section className="security-section" id='our-services'>
            <div className='container my-5'>
                <h2 className=' text-white text-center'>{middleBannerContent.title}</h2>
                <h3 className='text-white text-center'>{middleBannerContent.subtitle}</h3>
                <p>{middleBannerContent.description}</p>
                <div className="features row m-0">
                    {middleBannerContent.steps.map((step, index) => (
                        <div key={index} className="feature-card col-lg-4 col-12">
                            {step.image && <img src={step.image} alt="3D Secure checkouts" />}
                            <h3>{step.title.split('\n').map((line, index) => (
                                <span key={index}>
                                    {line}
                                    <br />
                                </span>
                            ))}</h3>
                            <p>{step.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ContentMiddleBanner;
