import React from 'react'
import HeroSection from '../components/Grants/HeroSection'
import GrantTimeline from '../components/Grants/GrantTimeline'
import ProblemSolutions from '../components/Grants/ProblemSolutions'

const Grants = () => {
  return (
    <>
      <HeroSection />
      <ProblemSolutions/>
    </>
  )
}

export default Grants
