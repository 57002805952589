import React from 'react';
import { NavLink } from 'react-router-dom';

const ProblemSolutions = () => {
    return (
        <section className="security-section" id='our-services'>
            <div className='container my-5'>
                <h2>PROBLEMS</h2>
                <h3>Challenges of a Centralized World</h3>
                <p>Centralized systems are prone to inefficiencies and inequities, including </p>
                <div className="features row ">
                    <div className="feature-card col-lg-2 col-md-3 col-sm-4 col-9">
                        {/* <img src="/images/svg1.svg" alt="3D Secure checkouts" /> */}
                        <p className='m-0'>Consolidation of power and wealth.
                        </p>
                    </div>
                    <div className="feature-card col-lg-2 col-md-3 col-sm-4 col-9">
                        {/* <img src="/images/svg2.svg" alt="PCI-compliant servers" /> */}
                        <p className='m-0'>Lack of transparency in social media algorithms.</p>
                    </div>
                    <div className="feature-card col-lg-2 col-md-3 col-sm-4 col-9">
                        {/* <img src="/images/svg3.svg" alt="Payment data encryption" /> */}
                        <p className='m-0'>Rising marketing and advertising costs.</p>
                    </div>
                    <div className="feature-card col-lg-2 col-md-3 col-sm-4 col-9">
                        {/* <img src="/images/svg1.svg" alt="3D Secure checkouts" /> */}
                        <p className='m-0'>Supply chain inefficiencies with costly intermediaries.
                        </p>
                    </div>
                    <div className="feature-card col-lg-2 col-md-3 col-sm-4 col-9">
                        {/* <img src="/images/svg2.svg" alt="PCI-compliant servers" /> */}
                        <p className='m-0'>Barriers to entry for startups due to opaque systems.</p>
                    </div>
                </div>
            </div>
            <div className='container my-5'>
                <h2>OUR SOLUTION</h2>
                <h3>An Integrated Decentralized Ecosystem</h3>
                <p>The AIBC Foundation proposes a unified ecosystem that integrates social media, ecommerce, supply chains, and global payment systems on a decentralized ledger equitably.<br /></p>
                <p style={{fontSize: '2rem'}}>This Solution</p>
                <div className="features row">
                    <div className="feature-card col-lg-2 col-md-3 col-sm-4 col-9">
                        {/* <img src="/images/svg1.svg" alt="3D Secure checkouts" /> */}
                        <p className='m-0'>Promotes transparency, leading to disintermediation and cost savings.
                        </p>
                    </div>
                    <div className="feature-card col-lg-2 col-md-3 col-sm-4 col-9">
                        {/* <img src="/images/svg2.svg" alt="PCI-compliant servers" /> */}
                        <p className='m-0'>Harnesses AI for predictive analytics and unparalleled efficiency.</p>
                    </div>
                    <div className="feature-card col-lg-2 col-md-3 col-sm-4 col-9">
                        {/* <img src="/images/svg3.svg" alt="Payment data encryption" /> */}
                        <p className='m-0'>Utilizes blockchain to democratize trust and decentralization.</p>
                    </div>
                    <div className="feature-card col-lg-2 col-md-3 col-sm-4 col-9">
                        {/* <img src="/images/svg1.svg" alt="3D Secure checkouts" /> */}
                        <p className='m-0'>Enhances security through blockchain andcybersecurity technologies.
                        </p>
                    </div>
                </div>
                <p className='mt-5'>Such an ecosystem will generate significant economic value by streamlining processes and reducing costs.</p>
            </div>
            <div className='container my-5'>
                {/* <h2>Ecosystem</h2> */}
                <img src="/ecosystem.jpg" className='img-fluid mb-5' style={{ width: '30rem' }} alt='Ecosystem' />
                <h2>AIBC’s Revolutionary Platforms</h2>
                <p>An Artificial Intelligence and Machine Learning based ecosystem that integrates social media, e-commerce, supply chains and global payment systems on a distributed ledger</p>
                <div className="features row">
                    <div className="feature-card col-lg-3 col-md-5 col-sm-8 col-10">
                        <div className='feature-card-image d-flex justify-content-center align-items-center' style={{ height: '100px' }}>
                            <img src="/images/shuffle.png" alt="3D Secure checkouts" style={{ width: '10rem' }} />
                        </div>
                        <h3>Shuffle Hub</h3>
                        <p className='m-0'>A social media platform enabling short-form video and live streaming for content creators powered by an algorithm governed on the blockchain.
                        </p>
                        <div className="get-your-app d-flex justify-content-center gap-3 mt-3">
                            <NavLink to='https://apps.apple.com/us/app/shuffle-by-krizaar/id6736882167'>
                                <img src="/app-store.png" className="img-fluid" style={{ width: '5rem' }} alt="App Store" />
                            </NavLink>
                            <NavLink to='https://play.google.com/store/apps/details?id=vr.shuffle.hub'>
                                <img src="/play-store-button.webp" className="img-fluid" style={{ width: '5rem' }} alt="App Store" />
                            </NavLink>
                        </div>
                    </div>
                    <div className="feature-card col-lg-3 col-md-5 col-sm-8 col-10">
                        <div className='feature-card-image d-flex justify-content-center align-items-center' style={{ height: '100px' }}>
                            <img src="/krizaar-logo.png" alt="PCI-compliant servers" style={{ width: '10rem' }} />
                        </div>
                        <h3>Krizaar</h3>
                        <p>A global blockchain-based e-commerce marketplace that enables trust and transparency while protecting users from counterfeit risks.</p>
                        <div className="get-your-app d-flex justify-content-center gap-3">
                            <NavLink to='https://apps.apple.com/us/app/shuffle-by-krizaar/id6736882167'>
                                <img src="/app-store.png" className="img-fluid" style={{ width: '5rem' }} alt="App Store" />
                            </NavLink>
                            <NavLink to='https://play.google.com/store/apps/details?id=com.app.krizaar'>
                                <img src="/play-store-button.webp" className="img-fluid" style={{ width: '5rem' }} alt="App Store" />
                            </NavLink>
                        </div>
                    </div>
                    <div className="feature-card col-lg-3 col-md-5 col-sm-8 col-10">
                        <div className='feature-card-image d-flex justify-content-center align-items-center' style={{ height: '100px' }}>
                            <img src="/flobux-logo.png" alt="Payment data encryption" style={{ width: '8rem' }} />
                        </div>
                        <h3>Flobux</h3>
                        <p>A blockchain-based freight tracking system that enables the transference of documentation and funds amongst third party logistics operators.</p>
                        <div className="get-your-app text-white">
                            ( App is in beta mode)
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProblemSolutions;
