import React from 'react';
import { NavLink } from 'react-router-dom';

const HeroSection = () => {
  return (
    <div className="hero-section">
      {/* <img src={heroimg} className='img-fluid' alt='background-img'/> */}
      <div className="video-background">
        <video autoPlay loop muted playsInline>
          <source src="/images/landing.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="container">
        <div className='hero-content'>

          <h3>AIBC Foundation</h3>
          <h1>Advancing AI, Blockchain & Cybersecurity</h1>
          <p>We are building the world’s first AI driven decentralized ledger that will enable social networks, e-commerce, logistics and payment systems to seamlessly be integrated with one another.</p>
          <div className="hero-buttons row m-0" style={{ width: 'fit-content !important' }}>
            {/* <div className=' d-flex jus'> */}
            <NavLink to="/ecosystem" className="btn btn-first col-lg-4 col-md-5 col-sm-5 col-5">Ecosystem</NavLink>
            <NavLink to="/airdrop" className="btn btn-first col-lg-4 col-md-5 col-sm-5 col-5">Airdrop</NavLink>
            <NavLink to="/whitepaper" className="btn btn-first col-lg-4 col-md-5 col-sm-5 col-5">Whitepaper</NavLink>
            <NavLink to="/donate" className="btn btn-first col-lg-4 col-md-5 col-sm-5 col-5">Donate Now</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
